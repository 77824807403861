import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Row, Col, Input, TreeSelect } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import { useDebounce } from 'use-debounce'
import { useQuery } from '@apollo/client'

import resourcesActions from 'actions/ResourcesActions'
import YearSelect from 'components/YearSelect'
import MonthSelect from 'components/MonthSelect'
import CategorySelect from 'components/categories/CategorySelect'
import TransactionTable from './TransactionTable'
import { LIST_ALL_TRANSACTION } from 'graphql/transactions'
import CashInfo from 'components/CashInfo'
import { c } from 'utils/currencies'
import useJsonQueryParams from 'hooks/useJsonQueryParams'
import usePageSize from 'hooks/usePageSize'
import ExportDrawerContainer from 'components/transactions/ExportDrawerContainer.js'
import { useTranslation } from 'react-i18next'

const { SHOW_ALL } = TreeSelect

const now = moment()

const AllTransactionList = ({ type }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [pageSize, setPageSize] = usePageSize()
  const [page, setPage] = useState(1)

  const [filters, setFilters] = useJsonQueryParams()
  const year = useMemo(() => filters.year || now.year(), [filters.year])

  const [searchText, setSearchText] = useState(filters.search)
  const [search] = useDebounce(searchText, 250)

  const { loading, data } = useQuery(LIST_ALL_TRANSACTION, {
    variables: {
      type,
      year,
      month: filters.month,
      orderBy: filters.orderBy,
      order: filters.order,
      categoryIds: filters.categoryIds,
      search: filters.search,
      offset: (page - 1) * pageSize,
      limit: pageSize
    }
  })

  const searchLoading = useMemo(() => {
    return loading || filters.search !== searchText
  }, [loading, filters.search, searchText])

  const catIdsString = useMemo(() => filters.categoryIds?.join(''), [filters.categoryIds])

  // WHEN A PARAMETER CHANGE SHOULD JUMP TO FIRST PAGE
  useEffect(() => {
    setPage(1)
  }, [year, filters.month, filters.orderBy, filters.order, filters.search, catIdsString])

  const pagination = {
    onChange: (page) => {
      setPage(page)
    },
    onShowSizeChange: (page, pageSize) => {
      setPage(page)
      setPageSize(pageSize)
    },
    current: page,
    pageSize,
    showSizeChanger: true,
    total: data?.allTransactions?.total
  }

  useEffect(() => {
    dispatch(resourcesActions.READ_CATEGORIES(type))
  }, [dispatch, type])

  const handleCategorySelectChange = useCallback((categoryIds) => {
    const length = get(categoryIds, 'length', 0)
    const value = length > 0 ? categoryIds : undefined

    setFilters({ ...filters, categoryIds: value })
  }, [setFilters, filters])

  const handleYearChange = useCallback((year) => {
    setFilters({ ...filters, year: year || undefined })
  }, [setFilters, filters])

  const handleMonthChange = useCallback((month) => {
    setFilters({ ...filters, month: month === 'allYear' ? undefined : month })
  }, [setFilters, filters])

  const handleSearchTextChange = useCallback((searchText) => {
    setSearchText(searchText || undefined)
  }, [setSearchText])

  const handleOrderChange = useCallback(({ order, orderBy }) => {
    setFilters({
      ...filters,
      order: order || undefined,
      orderBy: order && orderBy ? orderBy : undefined
    })
  }, [setFilters, filters])

  const handleSearchChange = useCallback(() => {
    setFilters({ ...filters, search })
  }, [setFilters, filters, search])
  useEffect(() => {
    handleSearchChange()
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [search] // create infinite loop if we add handleSearchChange in deps
  )

  return (
    <div id='rc-kompassify-all-transaction-list' className='mb-12'>
      <Row justify='space-between' gutter={[20, 20]}>
        <Col xs={8} lg={3}>
          <YearSelect onChange={handleYearChange} defaultValue={year} />
        </Col>

        <Col xs={8} lg={3}>
          <MonthSelect onChange={handleMonthChange} defaultValue={filters.month || 'allYear'} />
        </Col>

        <Col xs={12} lg={6}>
          <CategorySelect
            value={filters.categoryIds}
            type={type}
            onChange={handleCategorySelectChange}
            loading={searchLoading}
            treeCheckable
            showCheckedStrategy={SHOW_ALL}
            withEmpty
            placeholder={t('component.allTransactionList.filterByCategory')}
            className='rc-multi-category-select w-full'
          />
        </Col>

        <Col xs={12} lg={6}>
          <div>
            <Input.Search
              defaultValue={filters.search}
              placeholder={t('component.allTransactionList.search')}
              onChange={event => handleSearchTextChange(event.target.value)}
              loading={searchLoading}
              allowClear
            />
          </div>
        </Col>

        <Col xs={12} lg={1} className='flex h-8 items-center'>
          <ExportDrawerContainer year={year} month={filters.month} categoryIds={filters.categoryIds} type={type} />
        </Col>

        <Col xs={8} lg={5} className='flex h-8 items-center justify-end'>
          <CashInfo
            loading={searchLoading}
            title={t('component.allTransactionList.total')}
            text={c(data?.allTransactions?.totalAmount)}
            color={type}
            size='small'
            inline
          />
        </Col>
      </Row>

      <Row className='rc-margin-top'>
        <Col xs={24}>
          <TransactionTable
            type={type}
            setOrder={handleOrderChange}
            orderBy={filters.orderBy}
            order={filters.order}
            dataSource={data?.allTransactions?.transactions}
            pagination={pagination}
            loading={loading}
          />
        </Col>
      </Row>
    </div>
  )
}

AllTransactionList.propTypes = {
  type: PropTypes.oneOf(['cashin', 'cashout'])
}

export default AllTransactionList
