import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import { get, isNumber } from 'lodash'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { CHANGE_TRANSACTION_VAT_RATE } from 'graphql/transactions'
import VatSelect from 'components/vat/VatSelect'
import CategorizationHelperContext from 'contexts/CategorizationHelperContext'
import Badge from 'design/Badge'

const TransactionVatSelect = ({ transaction, onSuccess }) => {
  const { t } = useTranslation()
  const categorizationHelper = useContext(CategorizationHelperContext)

  const onCompleted = useCallback((data) => {
    const vatRate = data?.changeTransactionVatRate?.transaction?.vatRate

    if (categorizationHelper && vatRate) {
      const description = data?.changeTransactionVatRate?.transaction?.description

      categorizationHelper.triggerHelper({
        categorizationType: 'vatRate',
        categorizationTypeId: vatRate,
        description
      })
    }
    if (onSuccess) onSuccess()
  }, [categorizationHelper, onSuccess])

  const [changeVatRateMutation, { loading }] = useMutation(CHANGE_TRANSACTION_VAT_RATE, { onCompleted })

  const changeVat = useCallback((vatRate) => {
    console.log(vatRate)
    if (isNumber(vatRate)) changeVatRateMutation({ variables: { id: transaction.id, vatRate } })
  }, [changeVatRateMutation, transaction])

  if (transaction.isVatPayment) {
    return (
      <Badge type='info' label={t('shared.VAT')} tooltipLabel={t('component.transactionVatSelect.badgeTooltip')} className='ml-2' />
    )
  }

  return (
    <VatSelect
      value={get(transaction, 'vatRate')}
      onChange={changeVat}
      loading={loading}
      bordered={false}
    />
  )
}

TransactionVatSelect.propTypes = {
  transaction: PropTypes.object,
  onSuccess: PropTypes.func
}

export default TransactionVatSelect
