import React, { useMemo, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, Redirect } from 'react-router-dom'
import { Button, Card, Layout, Modal, Typography } from 'antd'
import { useTranslation, Trans } from 'react-i18next'
import confetti from 'canvas-confetti'

import resourcesActions from 'actions/ResourcesActions'
import useOnboarding from 'hooks/useOnboarding'
import useFirstSync from 'hooks/useFirstSync'
import OnboardingContext from 'components/onboarding/OnboardingContext'
import useSearchParams from 'hooks/useSearchParams'
import { getBookDemoLink, openLiveChat } from 'utils/contact'
import OnboardingScreenWidthWarning from './onboarding/OnboardingScreenWidthWarning'
import useAuthenticatedContext from 'hooks/useAuthenticatedContext'
import useIsConnectingOrConnected from 'hooks/useIsConnectingOrConnected'
import { useMutation } from '@apollo/client'
import { END_ONBOARDING } from 'graphql/companies'
import featureConfig from 'config/features.js'

function r (min, max) {
  return Math.random() * (max - min) + min
}

const launchConfetti = () => confetti({
  angle: r(85, 95),
  spread: r(100, 200),
  particleCount: r(50, 120),
  origin: {
    y: 1
  }
})

export default () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { step } = useParams()
  const { error } = useSearchParams()
  const [modalVisible, setModalVisible] = useState(!!error)

  const { company } = useAuthenticatedContext()

  const onboardingEndAt = company?.onboardingEndAt
  const isConnectingOrConnected = useIsConnectingOrConnected()

  const initStepIndex = useMemo(() => {
    if (error) return 1

    return step || (isConnectingOrConnected ? 2 : 0)
  }, [step, isConnectingOrConnected, error])

  const [endOnboarding] = useMutation(END_ONBOARDING, {
    onCompleted: () => {
      launchConfetti()
    }
  })

  const onboarding = useOnboarding({
    initStepIndex,
    onEndCallback: endOnboarding
  })

  // Index 1 is connect bank step
  useFirstSync({ shouldStart: onboarding.currentStepIndex > 1 })

  const CurrentScreen = useMemo(() => {
    return onboarding.currentStep.screen
  }, [onboarding.currentStep.screen])

  useEffect(() => {
    dispatch(resourcesActions.READ_CATEGORIES('cashin'))
    dispatch(resourcesActions.READ_CATEGORIES('cashout'))
  }, [dispatch])

  if (onboardingEndAt) {
    return <Redirect to='/' />
  }

  return (
    <Layout>
      <OnboardingScreenWidthWarning currentStep={onboarding.currentStepIndex} />

      <Layout.Content className='rc-content'>
        <OnboardingContext.Provider value={onboarding}>
          <Modal
            open={modalVisible}
            centered
            footer={null}
            onCancel={() => setModalVisible(false)}
          >
            <Typography.Title level={4} align='center'>
              {t('onboardingScreen.problemWithBankConnection')}
            </Typography.Title>

            <Typography.Paragraph align='center'>
              {(featureConfig.demoLinkEnabled && (
                <Trans
                  i18nKey='onboardingScreen.contactUsOrBookADemo'
                  components={{
                    Button: <Button onClick={openLiveChat} type='link' className='p-0 border-none' />,
                    Link: <a href={getBookDemoLink()} target='_blank' rel='noopener noreferrer' />
                  }}
                />
              )) || (
                <Trans
                  i18nKey='onboardingScreen.contactUs'
                  components={{
                    Button: <Button onClick={openLiveChat} type='link' className='p-0 border-none' />
                  }}
                />
              )}
            </Typography.Paragraph>
          </Modal>

          <Card bodyStyle={{ width: '100%', display: 'flex', justifyContent: 'center' }} className='w-full min-h-full'>
            <CurrentScreen />
          </Card>
        </OnboardingContext.Provider>
      </Layout.Content>
    </Layout>
  )
}
