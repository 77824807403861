import { gql } from '@apollo/client'

export const GET_INVOICE_PORTAL_TOKEN = gql`
  query getInvoicePortalToken {
    getInvoicePortalToken {
      token
    }
  }
`

export const GET_INVOICE_UPLOADS = gql`
  query getInvoiceUploads {
    getInvoiceUploads {
      batchId
    }
  }
`
